<template>
    <div class="orders-history">
         <div class="order-history">
            <template v-if="filteredOrders.length > 0">
                <div v-for="(order, index) in filteredOrders" :key="index" class="order">
                    <div class="coupon-text">
                        <div class="order-content order-type-fuel">
                            <div class="icon">
                                <img style="width: 32px;" :src="getPicto(order.orderOrigin)" alt="">
                            </div>
                            <div class="order-product">
                                <span class="order-type">{{order.creationDate}}</span>
                                <span class="order-nb-articles"> {{$tc('ticketNumberOfProducts', getNbrProducts(order), {nbrProducts: getNbrProducts(order)})}} <span>- {{$t(getSubStateTranslation(order.subState))}}</span></span>
                                <span @click="order.show = !order.show" class="show-more" ><u class="mr-1">{{$t("moreDetails")}}</u><span :class="{'icon-fleche-down': !order.show, 'icon-fleche-up': order.show}"></span></span>
                            </div>
                            <span class="order-amount"><span>{{order.totalAmount.toFixed(2)}} €</span></span>
                        </div>
                        <b-collapse v-model="order.show">
                            <div class="order-summary">
                                <ProductBloc class="my-2" v-for="cartProduct in order.basketProducts" :key="cartProduct.id" :product="cartProduct.product" :forcedQty="cartProduct.qty" :showMore="false" withBorder fullWidth hideQty/>
                            </div>
                        </b-collapse>
                    </div>
                </div>
            </template>
            <template v-else>
                <span class="no-order-placeholder">{{$t("noOrderHistory")}}.</span>
            </template>
            <div class="filter-wrapper">
                <div class="filter-btn" @click="showFilters()"><span class="mx-1 icon-filter"></span>{{$t("filterBtnLabel")}}</div>
                <div v-if="showFilterSection" :class="{'filter-section': true, 'show': startFilterTransition}">
                    <div class="filter-section-header">
                        <span class="filter-section-title">{{$t("historyFiltersHeader")}}:</span>
                        <span class="ml-auto icon-close" @click="closeFilters()"></span>
                    </div>
                    <div class="filter-options-wrapper">
                        <span class="option-title">{{$t("filterByOriginLabel")}} :</span>
                        <div class="filter-options">
                            <div class="filter-option" v-for="(origin, i) in filters.orderOrigin" :key="'label-' + i">
                                <div class="custom-radio-wrapper" @click="updateFilters(origin.value)">
                                    <span :class="{'custom-radio': true, 'checked': selectedFilters.indexOf(origin.value) != -1}"></span>
                                    <input type="checkbox" v-model="selectedFilters" :id="`filter-${i}`" :value="origin.value" required>
                                </div>
                                <label :for="`filter-${i}`">{{origin.label}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ProductBloc from "@/components/Food/ProductBloc";
const basket = require('@/assets/js/Food/Basket');
export default {
    components: { ProductBloc }, 
    data(){
        return {
            orders: [],
            page: {
                title: this.$t("pages.orderHistory.title"),
                description: null,
            },
            showFilterSection: false,
            startFilterTransition: false,
            selectedFilters:[],
            filters: {
                orderOrigin:[
                    {
                        label: "Click&Collect",
                        value: "IEC_CLICK_AND_COLLECT",
                    },
                    {
                        label: "Scan&Pay",
                        value: "IEC_SCAN_AND_PAY",
                    },
                    {
                        label: "Pay@Table",
                        value: "IEC_PAY_AT_TABLE",
                    }
                ]
            }
        }
    },
    async created(){
        let { data } = await basket.getCustomerBasketsByState("FINISH")
        this.orders = data
        this.selectedFilters = this.filtersValues
        console.log(this.filteredOrders[0].subState);
    },
    methods: {
        getNbrProducts(order){
            return order.basketProducts.reduce((acc, basketProduct) => {return acc + basketProduct.qty}, 0)
        },
        getPicto(origin){
            let picto = null
            switch(origin){
                case 'IEC_SCAN_AND_PAY':
                    picto = require("@/assets/img/picto_scan_pay.png")
                    break;
                case 'IEC_CLICK_AND_COLLECT':
                    picto = require("@/assets/img/picto_click_collect.png")
                    break;
                case 'IEC_PAY_AT_TABLE':
                    picto = require("@/assets/img/picto_pay_at_table.png")
                    break;
            }
            return picto
        },
        getSubStateTranslation(substate){
            let substateTranslation
            switch(substate){
                case 'NEW':
                    substateTranslation = "newSubstate"
                    break;
                case 'IN_PREPARATION':
                    substateTranslation = "inPreparationSubstate"
                    break;
                case 'PREPARED':
                    substateTranslation = "preparedSubstate"
                    break;
                case 'FINISH':
                    substateTranslation = "finishSubstate"
                    break;
                default:
                    substateTranslation = "newSubstate"
                    break;
            }
            return substateTranslation
        },
        showFilters(){
            this.showFilterSection = true
            setTimeout(() => {
                this.startFilterTransition = true
            }, 100);
        },
        closeFilters(){
            this.showFilterSection = false
            setTimeout(() => {
                this.startFilterTransition = false
            }, 100);
        },
        updateFilters(value){
            if(this.selectedFilters.indexOf(value) !== -1 ){
                this.selectedFilters = this.selectedFilters.filter(filter => filter != value)
            }else{
                this.selectedFilters.push(value)
            }
        }
    },
    computed:{
        filteredOrders(){
            if(this.selectedFilters.length > 0){
                return this.orders.filter(order => this.selectedFilters.includes(order.orderOrigin))
            }
            return this.orders
        },
        filtersValues(){
            let values = []
            for(let filter in this.filters){
                values = [...values, ...this.filters[filter].map(f => f.value)]
            }
            return values
        }
    }
}
</script>
<style scoped>
    .order-history{
        margin-top: 0;
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 60px;
    }

    .order-product{
        font-style: italic !important;
        color: #46b8a6 !important;
        font-weight: bold !important;
        flex-direction: column !important;
    }
    .order-content{
        flex-direction: row !important;
        justify-content: space-between !important;
        align-items: center !important;
    }
    .order-nb-articles{
        font-size: .85rem !important;
        font-weight: normal !important;
        color: #444444 !important;
    }
    .order-amount{
        align-self: center !important; 
        border-top: none !important; 
        margin-top: 0 !important;
    }
    .order{
        padding: 8px;
    }
    .show-more{
        display: flex;
        align-items: center;
        font-weight: normal;
        font-style: italic;
        font-size: .85rem;
        color: grey;
    }
    .show-more span{
        font-size: .75rem;
    }
    .filter-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        bottom: 0;
        left: auto;
        right: auto;
        margin-left: auto;
        width: 100%;
        z-index: 100;
    }
    .filter-wrapper .filter-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 8px auto;
        padding: 8px 24px;
        box-shadow: 0px 1px 8px #8080804a;
        border-radius: 50px;
        color: white;
        background: #46b8a6;
    }
    .filter-section{
        transition: all .5s ease-out;
        width: 90%;
        box-shadow: 0px 1px 8px #8080804a;
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
        padding: 8px 16px;
        position: absolute;
        z-index: 100;
        height: 0;
        opacity: 0;
        bottom: 0;
        background: white;
    }
    .filter-section.show{
        height: 350px;
        opacity: 1;
    }
    .filter-section-header{
        display: flex;
        justify-content: flex-end;
        padding: 8px 0;
        border-bottom: solid 1px #46b8a6;
        margin-bottom: 8px;
        font-size: 1.2rem;
    }
    .filter-options{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .filter-option{
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        grid-gap: 16px;
        padding: 8px;
    }
    .filter-option label{
        margin: 0;
    }
    .option-title{
        color: #46b8a6;
        font-weight: bold;
        font-size: 1.1rem;
    }
    .no-order-placeholder{
        font-style: italic;
        font-size: 1.2rem;
        color: grey;
        font-weight: bold;
    }
</style>