import {CATALOG_URL} from "@/assets/js/constantUtils";
import {capitalizeFirstLetter} from "@/assets/js/Utils";
const axios = require("axios")

export const nutriscoreMixin = {
    data(){
        return {
            openFoodFactsData: null
        }
    },
    methods:{
        async fetchData(productEan){
            console.log("fetching data from openfood fact")
            let response = await axios.get(CATALOG_URL.GET_NUTRISCORE().format({productEan}))
            this.openFoodFactsData = response.data
            return null
        },
        getNutriscore(){
            if( this.openFoodFactsData && this.openFoodFactsData.product && this.openFoodFactsData.product.nutriscore_grade){
                return(this.openFoodFactsData.product.nutriscore_grade.toUpperCase())
            }
            return null
        },
        getIngredients(){
            if(this.openFoodFactsData && this.openFoodFactsData.product && this.openFoodFactsData.product.ingredients_text_with_allergens){
                return(this.openFoodFactsData.product.ingredients_text_with_allergens)
            }
            return null
        },
        getAllergens(){
            if(this.openFoodFactsData && this.openFoodFactsData.product && this.openFoodFactsData.product.allergens_hierarchy ){
                let lang = this.$i18n.locale
                let translatedAllergens = this.openFoodFactsData.product.allergens_hierarchy.filter(allergen => allergen.indexOf(`${lang}:`) > -1)
                let formattedAllergens = translatedAllergens.map(allergen => capitalizeFirstLetter(allergen.split(`${lang}:`)[1])).join(", ")
                if(!translatedAllergens || translatedAllergens.length == 0){
                    translatedAllergens = this.openFoodFactsData.product.allergens_hierarchy.filter(allergen => allergen.indexOf(`en:`) > -1)
                    formattedAllergens = translatedAllergens.map(allergen => capitalizeFirstLetter(allergen.split(`en:`)[1])).join(", ")
                }
                return formattedAllergens
            }
            return null
        },
        getNutrimentsFor100g(){
            if(this.openFoodFactsData && this.openFoodFactsData.product && this.openFoodFactsData.product.nutriments){
                let rawNutriments = this.openFoodFactsData.product.nutriments
                let nutrimentsToShow = []
                nutrimentsToShow.push({
                    name:{
                        en: "Energy"
                    },
                    value: rawNutriments.energy_100g,
                    unit: rawNutriments.energy_unit
                })
                nutrimentsToShow.push({
                    name:{
                        en: "Fibers"
                    },
                    value: rawNutriments.fiber_100g,
                    unit: rawNutriments.fiber_unit
                })
                nutrimentsToShow.push({
                    name:{
                        en: "Carbs"
                    },
                    value: rawNutriments.carbohydrates_100g,
                    unit: rawNutriments.carbohydrates_unit
                })
                nutrimentsToShow.push({
                    name:{
                        en: "Sugar"
                    },
                    value: rawNutriments.sugars_100g,
                    unit: rawNutriments.sugars_unit
                })
                return nutrimentsToShow
            }
            return null
            
        }
    }
}